import React from "react";
const LockedComponent = ({ slug }) => {
    return (<section className="default-component locked">
			<div className="lock-image-container">
				<img className="lock-image" src="/assets/images/icons/lock.svg"/>
			</div>
			<span className="default-message">
				You need to be a member of the insider.in community to view this. Login to become one.
			</span>
			<a className="default-action-locked" rel="nofollow" href={`/users/login?nextUrl=${slug}`}>
				{" "}
				Login or Sign up{" "}
			</a>
		</section>);
};
export default LockedComponent;
